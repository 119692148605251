import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

import './QuoteSection.scss';

const QuoteSection = ({
  type,
  shapePath,
  content,
  author,
  image,
  className,
}) => (
  <div className="container quote-section__container">
    <div className={`quote-section quote-section--${type} ${className}`}>
      <div className="quote-section__content-wrapper">
        <h2>{content}</h2>
        <div className="quote-section__author">
          <Img className="quote-section__author-image" fixed={author.image} />
          <div className="quote-section__author-info">
            <h3>{author.name}</h3>
            <p>{author.title}</p>
          </div>
        </div>
      </div>
      <div className="quote-section__image-wrapper">
        <div className="quote-section__image">{image}</div>
        <svg
          width="610"
          viewBox="0 0 610 568"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="quote-section__shape"
        >
          <path d={shapePath} fill="url(#quote_shape)" opacity="0.1" />
        </svg>
      </div>
    </div>
  </div>
);

QuoteSection.defaultProps = {
  type: 'right',
  className: '',
};

QuoteSection.propTypes = {
  type: PropTypes.oneOf(['right', 'left']),
  shapePath: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired,
  image: PropTypes.node.isRequired,
  author: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    image: PropTypes.object,
  }).isRequired,
  className: PropTypes.string,
};

export default QuoteSection;
