import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const PI = 3.1415927;

/**
 * Calculate circumference
 * @param {Number} radius - Radius of the circle
 */
const getDashValue = radius =>
  useMemo(() => Math.round(2 * PI * radius), radius);

/**
 * Calculate circumference from a radius and a progress percentage
 * @param {Number} radius - Radius of the circle
 * @param {Number} progress - Progress of slider animation. Number from 0 to 100
 */
const getProgressValue = (radius, progress) => {
  const dashValue = getDashValue(radius);

  return dashValue - dashValue * progress * 0.01;
};

const SliderDot = ({ isActive, onButtonClick, progressValue }) => (
  <svg
    role="button"
    tabIndex="0"
    className={classnames('slider-dot', { 'slider-dot--active': isActive })}
    width="23"
    height="23"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onButtonClick}
    onKeyPress={onButtonClick}
  >
    <circle cx="11.5" cy="11.5" r="9.25" className="slider-dot__border-dot" />
    <circle
      cx="11.5"
      cy="11.5"
      r="10"
      className="slider-dot__progress-dot"
      strokeDasharray={getDashValue(10)}
      strokeDashoffset={getProgressValue(10, progressValue)}
    />
    <circle cx="11.5" cy="11.5" r="5" className="slider-dot__center-dot" />
  </svg>
);

SliderDot.defaultProps = {
  isActive: false,
};

SliderDot.propTypes = {
  isActive: PropTypes.bool,
  onButtonClick: PropTypes.func.isRequired,
  progressValue: PropTypes.number.isRequired,
};

export default SliderDot;
