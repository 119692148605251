import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Logo from '../shared/Logo/Logo';
import Button from '../shared/Button/Button';

import Arrow from '../../icons/arrow.inline.svg';

import './Navigation.scss';

const navigationItems = [
  {
    path: '/our-product',
    name: 'Our Product',
  },
  {
    path: '/what-are-missions',
    name: 'What are Missions?',
  },
  {
    path: '/why-edwin',
    name: 'Why Edwin',
  },
  {
    path: '/about-us',
    name: 'About us',
  },
];

const renderNavigationItem = item => (
  <li className="navigation__item" key={item.name}>
    <Link
      to={item.path}
      className="navigation__link"
      activeClassName="navigation__link--active"
    >
      <Arrow />
      {item.name}
    </Link>
  </li>
);

const Navigation = ({ isNavigationActive }) => (
  <div
    className={`navigation ${isNavigationActive ? 'navigation--active' : ''}`}
  >
    <Logo className="navigation__logo" />
    <nav>
      <ul className="navigation__list">
        {navigationItems.map(renderNavigationItem)}
      </ul>
    </nav>
    <div className="navigation__footer">
      {/* <Button
        fluid={false}
        to="/sign-in"
        type="primary"
        className="navigation__button navigation__button--primary"
      >
        Sign In
      </Button> */}
      <Button
        fluid
        to="/book-demo"
        type="secondary"
        className="navigation__button navigation__button--secondary"
      >
        Book Demo
      </Button>
    </div>
  </div>
);

Navigation.propTypes = {
  isNavigationActive: PropTypes.bool.isRequired,
};

export default Navigation;
