import { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';

const FIXED_OFFSET_HEIGHT = 100;
const SHOW_HEADER_OFFSET_HEIGHT = 300;
const MOBILE_WIDTH = 1240;

const useHeader = () => {
  const [isNavigationOpen, toggleNavigation] = useState(false);

  const [isHeaderFixed, setHeaderFixed] = useState(false);
  const [isHeaderShown, showHeader] = useState(false);
  const [isMoblieSize, setMobileSize] = useState(false);
  const [prevScroll, setPrevScroll] = useState(0);

  const onToggleNavigation = useCallback(
    () => toggleNavigation(!isNavigationOpen),
    [isNavigationOpen],
  );

  const setFixedHeader = scrollPosition => {
    setHeaderFixed(scrollPosition >= FIXED_OFFSET_HEIGHT);
    if (scrollPosition >= FIXED_OFFSET_HEIGHT) {
      document.body.classList.add('body--header');
    } else {
      document.body.classList.remove('body--header');
    }
  };

  const handleScroll = () => {
    const scrollPosition =
      window.pageYOffset || document.documentElement.scrollTop;

    setFixedHeader(scrollPosition);

    if (!isMoblieSize) {
      showHeader(scrollPosition > SHOW_HEADER_OFFSET_HEIGHT);
    } else {
      showHeader(
        scrollPosition > SHOW_HEADER_OFFSET_HEIGHT &&
          prevScroll > scrollPosition,
      );
    }

    setPrevScroll(scrollPosition <= 0 ? 0 : scrollPosition);
  };

  const debounceScroll = debounce(handleScroll, 18);
  useEffect(() => {
    if (isNavigationOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }

    setMobileSize(window.innerWidth < MOBILE_WIDTH);
    window.addEventListener('scroll', debounceScroll);

    return () => {
      window.removeEventListener('scroll', debounceScroll);
    };
  });

  return {
    onToggleNavigation,
    isNavigationOpen,
    isHeaderShown,
    isHeaderFixed,
  };
};

export default useHeader;
