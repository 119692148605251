import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import './Button.scss';

const Button = ({ to, type, children, className, fluid, onClick }) => {
  if (to === '') {
    return (
      <button
        type="button"
        onClick={onClick}
        className={classnames(
          `button button--${type}`,
          { 'button--resize-off': fluid },
          className,
        )}
      >
        {children}
      </button>
    );
  }

  return (
    <Link
      to={to}
      className={classnames(
        `button button--${type}`,
        { 'button--resize-off': fluid },
        className,
      )}
      activeClassName="button--active"
    >
      {children}
    </Link>
  );
};

Button.defaultProps = {
  fluid: true,
  type: 'primary',
  className: '',
  to: '',
  onClick: () => {},
};

Button.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.string,
  fluid: PropTypes.bool,
  type: PropTypes.oneOf(['primary', 'secondary']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Button;
