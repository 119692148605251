import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import LogoIcon from '../../../icons/logo.inline.svg';

import './Logo.scss';

const Logo = ({ className }) => (
  <Link to="/" className={`logo ${className}`}>
    <LogoIcon />
  </Link>
);

Logo.defaultProps = {
  className: '',
};

Logo.propTypes = {
  className: PropTypes.string,
};

export default Logo;
