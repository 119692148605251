import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import './HeaderSection.scss';

const HeaderSection = ({ icon, subTitle, title, description, className }) => (
  <div className={`header-section ${className}`}>
    <div className="header-section__icon">{icon}</div>
    <h4>{subTitle}</h4>
    <ReactMarkdown source={`## ${title}`} escapeHtml={false} />
    {description && (
      <p className="header-section__description">{description}</p>
    )}
  </div>
);

HeaderSection.defaultProps = {
  icon: undefined,
  description: '',
  className: '',
};

HeaderSection.propTypes = {
  icon: PropTypes.element,
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
};

export default HeaderSection;
