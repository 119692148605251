import React from 'react';

const SvgDefs = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="0"
      height="0"
      style={{
        display: 'block',
      }}
    >
      <defs>
        <linearGradient
          id="linearGradient"
          x1="191.513"
          y1="232.037"
          x2="68.1707"
          y2="3.65399"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.25" stopColor="#08C77D" />
          <stop offset="1" stopColor="#8491A1" />
        </linearGradient>
        <linearGradient
          id="shape_gradient"
          x1="253.227"
          y1="13.804"
          x2="484.733"
          y2="586.6"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0548717" stopColor="#01DB87" />
          <stop offset="0.558868" stopColor="#88EAB9" />
          <stop offset="1" stopColor="#FFF8E5" />
        </linearGradient>
        <linearGradient
          id="quote_shape"
          x1="435.906"
          y1="527.187"
          x2="155.951"
          y2="7.87779"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.25" stopColor="#08C77D" />
          <stop offset="1" stopColor="#8491A1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SvgDefs;
