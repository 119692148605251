import React from 'react';
import PropTypes from 'prop-types';

import SvgDefs from './shared/SvgDefs/SvgDefs';
import Footer from './Footer/Footer';

import '../sass/index.scss';

const Layout = ({ children }) => (
  <>
    <SvgDefs />
    <main className="layout">{children}</main>
    <Footer />
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
