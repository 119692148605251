import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ContentFullWidth = ({ content, activeElement, id }) => (
  <div
    className={classnames(
      'demo-section__full-view',
      'demo-section__extra-image',
      {
        'demo-section__full-view--active': activeElement === id,
      },
    )}
  >
    <div className="demo-section__images">
      <img src={content.src.publicURL} alt="demo-content" />
    </div>
  </div>
);

ContentFullWidth.propTypes = {
  content: PropTypes.shape({
    src: PropTypes.shape({
      extension: PropTypes.string,
      publicURL: PropTypes.string,
    }),
    type: PropTypes.oneOf(['box', 'box-padding', 'full-width']),
  }).isRequired,
  activeElement: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
};

export default ContentFullWidth;
