import React from 'react';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

import CustomersIcon from '../../../icons/ilustration_05.inline.svg';

import './Customers.scss';

const renderCustomersBox = box => (
  <div key={box.node.id} className="customers__box">
    <Img fluid={box.node.childImageSharp.fluid} />
  </div>
);

const Customers = () => {
  const {
    files: { customers },
  } = useStaticQuery(graphql`
    {
      files: allFile(
        filter: { relativePath: { regex: "/customers//" } }
        sort: { fields: name }
      ) {
        customers: edges {
          node {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_noBase64
                presentationWidth
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div className="customers">
      <div className="container customers__container">
        <div className="customers__ilustration">
          <CustomersIcon />
        </div>
        <div className="customers__right-section">
          <h2>
            <span className="text-main-color">Changing security behaviors</span>{' '}
            for the world’s best teams and organizations
          </h2>
          <div className="customers__box-wrapper">
            {customers.map(renderCustomersBox)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
