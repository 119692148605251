import debounce from 'lodash.debounce';
import { useState, useEffect, useRef } from 'react';

const useSlider = (allSlides, animationSpeed) => {
  const [activeSlideId, setActiveSlide] = useState(0);
  const [progressValue, setProgressValue] = useState(0);
  const [isAutoPlayStopped, stopAutoPlay] = useState(true);
  const [isSliderVisable, setIsSliderVisable] = useState(false);
  const sliderElementRef = useRef(null);

  const TIMEOUT_TIME = 40;
  let timeoutFunction = null;

  const setTimeoutFunction = () => {
    const nextProgressValue =
      progressValue + (TIMEOUT_TIME * 100) / animationSpeed;

    timeoutFunction = setTimeout(() => {
      if (nextProgressValue > 100) {
        setProgressValue(0);
        // eslint-disable-next-line no-shadow
        setActiveSlide(activeSlideId =>
          activeSlideId + 1 >= allSlides ? 0 : activeSlideId + 1,
        );
      } else {
        setProgressValue(nextProgressValue);
      }
    }, TIMEOUT_TIME);
  };

  const setSlide = index => {
    if (index !== activeSlideId) {
      clearTimeout(timeoutFunction);

      setProgressValue(0);
      setActiveSlide(index);
    }
  };

  useEffect(() => {
    if (!isAutoPlayStopped) {
      setTimeoutFunction();
    }
    return () => clearTimeout(timeoutFunction);
  }, [isAutoPlayStopped, progressValue]);

  const scrollHandle = () => {
    const elementOffsetFromTop = sliderElementRef.current.offsetTop;
    const scrollPositionFromBottom = window.pageYOffset + window.innerHeight;

    if (elementOffsetFromTop <= scrollPositionFromBottom) {
      setIsSliderVisable(true);
      stopAutoPlay(false);
    }
  };
  const debounceScroll = debounce(scrollHandle, 18);

  useEffect(() => {
    if (!isSliderVisable) {
      window.addEventListener('scroll', debounceScroll);
    } else {
      window.removeEventListener('scroll', debounceScroll);
    }
    return () => window.removeEventListener('scroll', debounceScroll);
  }, [isSliderVisable]);

  return {
    progressValue,
    activeSlideId,
    setSlide,
    stopAutoPlay,
    sliderElementRef,
  };
};

export default useSlider;
