import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import Img from 'gatsby-image';
import classnames from 'classnames';
import SliderDot from './SliderDot';
import Button from '../Button/Button';
import useSlider from '../../../utils/useSlider';

import SliderFlair from '../../../icons/sliderFlair.inline.svg';
import './SliderSection.scss';

const renderImage = (element, activeSlideId) => (
  <div
    key={element.id}
    className={classnames('slider-section__render-image', {
      'slider-section__render-image--active': element.id === activeSlideId,
    })}
  >
    <div
      className="slider-section__slider-flair"
      style={{
        top: `${element.image.flair.top}%`,
        left: `${element.image.flair.left}%`,
        transform: `rotate(${element.image.flair.rotate}deg)`,
      }}
    >
      <SliderFlair />
    </div>
    <Img
      fluid={element.image.src.childImageSharp.fluid}
      style={{
        maxWidth: element.image.src.childImageSharp.fluid.presentationWidth,
      }}
    />
  </div>
);

const renderImageWithActiveSlide = activeSlideId => image =>
  renderImage(image, activeSlideId);

const getButtonEvent = event =>
  typeof event === 'string' ? { to: event } : { onClick: event };

const SliderSection = ({ type, sliderContent, button, className }) => {
  const {
    progressValue,
    activeSlideId,
    setSlide,
    stopAutoPlay,
    sliderElementRef,
  } = useSlider(sliderContent.length, 4000);

  const onMouseEnter = useCallback(() => stopAutoPlay(true));
  const onMouseLeave = useCallback(() => stopAutoPlay(false));
  const activeSlide = sliderContent[activeSlideId];

  const renderDot = (dot, index) => (
    <SliderDot
      key={dot.id}
      isActive={index === activeSlideId}
      stopAutoPlay={stopAutoPlay}
      progressValue={index === activeSlideId ? progressValue : 0}
      onButtonClick={() => setSlide(index)}
      setNextSlide={() =>
        setSlide(
          activeSlideId + 1 >= sliderContent.length ? 0 : activeSlideId + 1,
        )
      }
    />
  );

  return (
    <div
      className={classnames('container slider-section__container', className)}
      ref={sliderElementRef}
    >
      <div className={`slider-section slider-section--${type}`}>
        <div className="slider-section__content-wrapper">
          <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <div className="slider-section__dots">
              {sliderContent.length > 1 && sliderContent.map(renderDot)}
            </div>
            <h2>
              <ReactMarkdown source={activeSlide.text} escapeHtml={false} />
            </h2>
            {button && (
              <Button type="secondary" {...getButtonEvent(button.onClick)}>
                {button.text}
              </Button>
            )}
          </div>
        </div>
        <div className="slider-section__image-wrapper">
          <div className="slider-section__image">
            {sliderContent.map(renderImageWithActiveSlide(activeSlideId))}
          </div>
          <svg
            width="610"
            viewBox="0 0 610 568"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="slider-section__shape"
          >
            <path
              d={activeSlide.shapePath}
              opacity="0.1"
              fill="#0DB876"
              transform="translate(50,0)"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

SliderSection.defaultProps = {
  className: '',
  type: 'right',
  button: null,
};

SliderSection.propTypes = {
  type: PropTypes.oneOf(['right', 'left']),
  className: PropTypes.string,
  button: PropTypes.shape({
    onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    text: PropTypes.string,
  }),
  sliderContent: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      shapePath: PropTypes.string,
      image: PropTypes.shape({
        src: PropTypes.shape({
          presentationWidth: PropTypes.number,
        }),
        flair: PropTypes.shape({
          top: PropTypes.number,
          left: PropTypes.number,
          rotate: PropTypes.number,
        }),
      }),
    }),
  ).isRequired,
};

export default SliderSection;
