import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import classnames from 'classnames';

const renderContent = (extension, content) => {
  if (extension === 'svg') {
    return <img src={content.src.publicURL} alt="content" />;
  }
  if (extension === 'png') {
    return (
      <Img
        className="demo-section__extra-image"
        fluid={content.src.childImageSharp.fluid}
      />
    );
  }
  return content.src.element;
};

const ContentBox = ({ content, thumbnail, paddings, activeElement, id }) => (
  <div
    className={classnames(
      'demo-section__scroll-view',
      { 'demo-section__scroll-view--paddings': paddings },
      { 'demo-section__scroll-view--svg': content.src.extension === 'svg' },
      { 'demo-section__scroll-view--png': content.src.extension === 'png' },
      { 'demo-section__scroll-view--active': activeElement === id },
    )}
    style={{
      width:
        content.src.extension === 'png' &&
        content.src.childImageSharp.fluid.presentationWidth / 2,
    }}
  >
    {thumbnail && (
      <div
        className={classnames(
          'demo-section__thumbnail',
          `demo-section__thumbnail--${thumbnail.position}`,
        )}
      >
        <Img fixed={thumbnail.src.childImageSharp.fixed} />
      </div>
    )}
    <div
      className="demo-section__images"
      style={{
        width:
          content.src.extension === 'png' &&
          content.src.childImageSharp.fluid.presentationWidth / 2,
      }}
    >
      {renderContent(content.src.extension, content)}
    </div>
  </div>
);

ContentBox.defaultProps = {
  thumbnail: null,
  paddings: false,
};

ContentBox.propTypes = {
  paddings: PropTypes.bool,
  content: PropTypes.shape({
    src: PropTypes.shape({
      extension: PropTypes.string,
      publicURL: PropTypes.string,
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.shape({
          presentationWidth: PropTypes.number,
        }),
      }),
      type: PropTypes.oneOf(['box', 'box-padding', 'full-width']),
    }),
  }).isRequired,
  thumbnail: PropTypes.oneOfType([
    PropTypes.shape({
      src: PropTypes.object,
      position: PropTypes.oneOf(['left', 'right']),
    }),
    PropTypes.oneOf([null]),
  ]),
  activeElement: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
};

export default ContentBox;
