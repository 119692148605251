import React from 'react';
import Button from '../Button/Button';
import FinalCtaIcon from '../../../icons/ilustration_06.inline.svg';

import './FinalCta.scss';

const FinalCta = () => (
  <div className="final-cta">
    <div className="container final-cta__container">
      <div className="final-cta__first-column">
        <h2>
          Help secure the people in your organization—their work and their
          lives.
        </h2>
        <div className="final-cta__btn">
          <Button
            to="/book-demo"
            type="primary"
            className="final-cta__button final-cta__button--first"
          >
            Book a Demo
          </Button>
          <Button
            to="/our-product"
            type="secondary"
            className="final-cta__button final-cta__button--second"
          >
            Learn More
          </Button>
        </div>
      </div>
      <div className="final-cta__second-column">
        <FinalCtaIcon />
      </div>
    </div>
  </div>
);

export default FinalCta;
