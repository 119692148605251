import React from 'react';
import PropTypes from 'prop-types';

import ContentBox from './ContentBox/ContentBox';
import ContentFullWidth from './ContentFullWidth/ContentFullWidth';

import './DemoSection.scss';

const returnContentByType = activeElement => element => {
  const contentByType = {
    box: (
      <ContentBox
        key={element.id}
        id={element.id}
        activeElement={activeElement}
        content={element.content}
        thumbnail={element.thumbnail}
      />
    ),
    'box-padding': (
      <ContentBox
        key={element.id}
        id={element.id}
        paddings
        activeElement={activeElement}
        content={element.content}
        thumbnail={element.thumbnail}
      />
    ),
    'full-width': (
      <ContentFullWidth
        key={element.id}
        id={element.id}
        activeElement={activeElement}
        content={element.content}
        thumbnail={element.thumbnail}
      />
    ),
  };

  return contentByType[element.content.type];
};

const DemoSection = ({ demos, activeElement }) => (
  <div className="demo-section">
    <div className="demo-section__content">
      {demos.map(returnContentByType(activeElement))}
    </div>
    <svg
      className="demo-section__shape"
      width="580"
      viewBox="0 0 580 540"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={demos[activeElement].shapePath} fill="url(#shape_gradient)" />=
    </svg>
  </div>
);

DemoSection.propTypes = {
  demos: PropTypes.arrayOf(
    PropTypes.shape({
      thumbnail: PropTypes.oneOfType([
        PropTypes.shape({
          src: PropTypes.object,
          position: PropTypes.oneOf(['left', 'right']),
        }),
        PropTypes.oneOf([null]),
      ]),
      content: PropTypes.shape({
        src: PropTypes.shape({
          extension: PropTypes.string,
        }),
        type: PropTypes.oneOf(['box', 'box-padding', 'full-width']),
      }).isRequired,
      shapePath: PropTypes.string.isRequired,
    }),
  ).isRequired,
  activeElement: PropTypes.number.isRequired,
};

export default DemoSection;
