import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Logo from '../shared/Logo/Logo';
import Navigation from '../Navigation/Navigation';
import Button from '../shared/Button/Button';

import CloseMenu from '../../icons/closeMenu.inline.svg';
import HamburgerMenu from '../../icons/hamburgerMenu.inline.svg';
import './Header.scss';
import useHeader from '../../utils/useHeader';

const Header = ({ demoButtonNavigation }) => {
  const {
    isNavigationOpen,
    isHeaderFixed,
    isHeaderShown,
    onToggleNavigation,
  } = useHeader();

  const buttonEvent =
    typeof demoButtonNavigation === 'string'
      ? { to: demoButtonNavigation }
      : { onClick: demoButtonNavigation };

  return (
    <header
      className={classnames(
        'header',
        { 'header--fixed': isHeaderFixed },
        { 'header--show': isHeaderShown },
      )}
    >
      <div className="container header__container">
        <Logo className="header__logo" />
        <Navigation
          className="header__navigation"
          isNavigationActive={isNavigationOpen}
        />
        <div className="header__right">
          {/* <Button to="/" type="primary" className="header__button">
            Sign In
          </Button> */}
          <Button
            fluid={false}
            {...buttonEvent}
            type="secondary"
            className="header__button header__button--margin-right"
          >
            Book Demo
          </Button>
          <button
            className="header__toggle-button"
            type="button"
            onClick={onToggleNavigation}
          >
            {isNavigationOpen ? <CloseMenu /> : <HamburgerMenu />}
          </button>
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  demoButtonNavigation: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
    .isRequired,
};

export default Header;
