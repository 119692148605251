const shapePaths = [
  'M598.43 210.1C648.734 298.851 526.886 493.187 389.754 549.568C272.82 597.632 101.945 552.111 32.2787 408.104C-83.9616 167.912 137.729 -104.969 316.44 40.9233C460.504 158.531 561.67 132.524 598.43 210.1Z',
  'M194.948 74.2878C262 25.4703 332 -29.7602 437.579 19.2113C512.314 53.8758 544.908 108.391 540.967 177.692C537.027 246.992 543.3 247.986 588.887 305.343C634.475 362.701 609.043 500.673 485.063 498.341C361.083 496.01 202.456 667.233 51.2217 473.64C-63.5281 326.575 27.6228 196.111 194.948 74.2878Z',
  'M185.361 72.5272C249.115 26.1161 315.672 -26.3917 416.059 20.1657C487.118 53.1214 518.109 104.95 514.362 170.834C510.616 236.718 516.58 237.662 559.926 292.193C603.271 346.723 579.09 477.894 461.208 475.677C343.325 473.46 192.499 636.242 48.7026 452.193C-60.4038 312.378 26.2643 188.345 185.361 72.5272Z',
  'M412.977 0.0111713C525.49 0.957942 628.848 116.678 607.078 288.248C588.502 434.541 450.791 580.742 274.823 567.116C-18.7006 544.432 -98.1313 251.531 139.772 156.001C277.634 100.642 265.921 -1.22628 412.977 0.0111713Z',
  'M332.57 442.407C249.594 543.998 104.037 533.372 41.7596 475.162C-70.5618 370.174 94.2858 287.529 36.0434 201.171C-4.20838 141.488 -20.1775 76.7602 38.4859 29.0353C142.33 -55.4462 230.175 66.5684 289.105 114.51C366.774 177.698 492.512 78.6569 549.595 209.776C590.222 303.099 456.084 291.184 332.57 442.407Z',
  'M568.999 199.743C616.829 284.119 500.974 468.875 370.586 522.477C259.403 568.172 96.9317 524.894 30.6912 387.986C-79.8323 159.635 130.956 -99.7949 300.878 38.906C437.856 150.716 534.047 125.992 568.999 199.743Z',
  'M428.201 478.462C279.221 559.505 127.796 575.179 41.7828 434.809C-44.2308 294.438 6.81349 114.947 155.793 33.904C304.773 -47.1389 450.798 25.1495 536.812 165.52C622.825 305.891 577.181 397.419 428.201 478.462Z',
  'M376.851 562.579C211.082 591.711 -35.6969 499.994 4.32227 369.791C40.4957 228.497 214.732 264.877 252.942 150.577C279.349 71.584 301.804 -23.2969 423.641 5.1631C562.416 37.5798 639.464 239.588 599.445 369.791C577.041 442.684 519.63 537.488 376.851 562.579Z',
  'M233.149 562.579C398.918 591.711 645.697 499.994 605.678 369.791C569.504 228.497 395.268 264.877 357.058 150.577C330.651 71.584 308.196 -23.2969 186.359 5.1631C47.5845 37.5798 -29.4642 239.588 10.5549 369.791C32.9593 442.684 90.3698 537.488 233.149 562.579Z',
  'M181.781 523.767C77.9727 522.894 -17.3896 416.184 2.69592 257.971C19.8348 123.069 146.893 -11.7497 309.248 0.815186C580.065 21.7331 653.351 291.829 433.852 379.922C306.655 430.971 317.462 524.908 181.781 523.767Z',
  'M234.119 66.1852C327.763 29.3391 408.661 -57.7278 532.577 57.9464C656.492 173.621 621.887 377.123 497.972 492.797C385.436 597.847 200.81 621.08 48.454 374.744C-103.902 128.408 143.613 101.797 234.119 66.1852Z',
];

export default shapePaths;
