import React from 'react';
import { Link } from 'gatsby';

import Logo from '../shared/Logo/Logo';
import Arrow from '../../icons/arrow.inline.svg';
import './Footer.scss';

const menuElements = [
  {
    name: 'Our Product',
    url: '/our-product',
  },
  {
    name: 'What are Missions?',
    url: '/what-are-missions',
  },
  {
    name: 'Why Edwin?',
    url: '/why-edwin',
  },
  {
    name: 'About Us',
    url: '/about-us',
  },
  // {
  //   name: 'Contact Us',
  //   url: '/contact-us',
  // },
  {
    name: 'Book a Demo',
    url: '/book-demo',
  },
];

const renderFooterLink = item => (
  <li className="footer__item" key={item.name}>
    <Link
      to={item.url}
      className="footer__link"
      activeClassName="footer__link--active"
    >
      <Arrow />
      {item.name}
    </Link>
  </li>
);

const Footer = () => (
  <footer className="footer">
    <div className="container footer__container">
      <div className="footer__logo-column">
        <Logo className="footer_logo" />
      </div>
      <div className="footer__first-column">
        <nav>
          <ul className="footer__list">
            {menuElements.slice(0, 4).map(renderFooterLink)}
          </ul>
        </nav>
      </div>
      <div className="footer__second-column">
        <nav>
          <ul className="footer__list">
            {menuElements.slice(4, 6).map(renderFooterLink)}
          </ul>
        </nav>
      </div>
    </div>
    <div className="container footer__container-small-elements">
      <div className="footer__first-small-column">
        <p>NYC — Warsaw — Wroclaw — London</p>
      </div>
      <div className="footer__second-small-column">
        <p>©2021 Edwin Secure, Inc.</p>
      </div>
      <div className="footer__third-small-column">
        {/* <p>Policies & Disclosures</p> */}
      </div>
    </div>
  </footer>
);

export default Footer;
